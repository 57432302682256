import React, { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../styles/Breadcrumb.scss";
import { stateContext } from "../utills/statecontact";
interface BreadcrumbProps { }

const Breadcrumb: React.FC<BreadcrumbProps> = () => {
  const {
    state: {
      user_Data: { user_name },
    },
    dispatch,
  } = useContext(stateContext);
  const location = useLocation();
  console.log('location', location);

const pathLocal = sessionStorage.setItem("pathLocal", JSON.stringify(location?.pathname));
  const pathSegments = location?.pathname.split('/').filter((segment) => segment !== '');
  // console.log('pathSegments', pathSegments.length);


  const navigate = useNavigate();
  const isNumber = (value: string) => !isNaN(Number(value));

  let numericSegments: string[] = [];
  let textSegments: string[] = [];

  pathSegments.forEach(segment => {
    if (!isNaN(Number(segment))) {
      numericSegments.push(segment);
    } else {
      textSegments.push(segment);
    }
  });

  // console.log('Numeric Segments:', numericSegments);
  // console.log('Text Segments:', textSegments);
  // console.log(pathSegments.length);
  const handleBreadcrumbClick = (index: number) => {
    // If index is 0 and path starts with 'Solutions', navigate directly to '/Solutions'
    console.log('index:', index);

    if (index === 0 && pathSegments[0] === 'Solutions') {
      navigate('/Solutions');
    } else {
      if (pathSegments[index] === "Category") {
        const targetPath = `/${textSegments[0]}/${textSegments[1]}/${numericSegments[0]}`
        console.log('targetpath:', targetPath);
        navigate(targetPath);
      }
      else if (pathSegments[index] === "SubCategory") {
        const targetPath = `/${textSegments[0]}/${textSegments[1]}/${textSegments[2]}/${numericSegments[0]}/${numericSegments[1]}`
        console.log('targetpath:', targetPath);
        navigate(targetPath);
      }
      else if (
        console.log(textSegments[index]),
        textSegments[index] === "Versions") {
        const targetPath = `/${textSegments[0]}`;
        console.log('targetpath:', targetPath);
        navigate(targetPath);
      }
    }
  };

  if (pathSegments?.length >= 2) {
    return (
      <div className="Breadcrumb d-none d-md-block ps-3">
        <img src={require("../assets/Icons/arrowback.png")} alt="arrowback" style={{ cursor: "pointer" }} onClick={() => navigate(-1)} />
        {pathSegments.map((segment, index) => (
          <React.Fragment key={index}>
            {index === pathSegments.length - 1 ? (
              <h1>
                {index > 0 && (pathSegments[index - 1] === "Category" || pathSegments[index - 2] === "SubCategory")
                  ? "Overview"
                  : (
                    pathSegments[index - 4] ? pathSegments[index - 4] : (
                      <h1>
                        {pathSegments[1] == 'VersionDetails' ? pathSegments[1] : pathSegments[0]}
                      </h1>
                    )

                  )}
              </h1>
            ) : (
              <>
                {isNumber(segment?.replace(/^"(.*)"$/, '$1')) ? null : (
                  <>
                    {index > 0 && ' / '}
                    <span style={{ cursor: "pointer" }} onClick={() => handleBreadcrumbClick(index)}>{segment}</span>
                  </>
                )}
              </>
            )}
          </React.Fragment>
        ))}
      </div>
    );
  }
  return (
    <div className="Breadcrumb d-none d-md-block ps-2">
      <div>
        {pathSegments?.length == 3 && (
          console.log(pathSegments),
          <div>
            <span onClick={() => navigate(-1)}>{pathSegments[0]}</span>
            <h1>{pathSegments[0]}</h1>
          </div>
        )}
        {pathSegments?.length === 0 && (
          <div>
            <span>Dashboard</span>
            <h1>{user_name}</h1>
          </div>
        )}
        {pathSegments?.length === 1 && (
          console.log(pathSegments),
          <div>
            <span>{pathSegments[0]}</span>
            <h1>{pathSegments[0]}</h1>
          </div>
        )}
        {pathSegments?.length === 2 && (
          console.log(pathSegments),

          <div>
            <span onClick={() => navigate(-1)}>{pathSegments[0]}</span>
            <h1>{pathSegments[1]}</h1>
          </div>
        )}

      </div>
    </div>
  );
};
export default Breadcrumb;
