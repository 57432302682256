
import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import "../../src/styles/Sidebar.scss";
import { useLocation } from "react-router-dom";

const SideBar = ({ show, setShow }: { show: any; setShow: any }) => {
  const sidebarClasses = show
    ? "d-flex justify-content-start sidebar"
    : "d-none d-md-block sidebar";
  const location = useLocation();
  const pathSegments = location.pathname?.split("/").filter((segment) => segment !== "");

  useEffect(() => {
    if (window.innerWidth < 768) {
      setShow(false);
    }
  }, [setShow]);

  const sidebarRouter = [
    {
      topRouter: [
        {
          route: "Dashboard",
          icon: "dashboard.png",
        },
        {
          route: "Solutions",
          icon: "cloud.png",
        },
        {
          route: "Versions",
          icon: "inventory_2.png",
        },
      ],
      bottomRouter: [
        {
          route: "Settings",
          icon: "settings.png",
        },
        {
          route: "Help",
          icon: "help_outline.png",
        },
      ],
    },
  ];

  return (
    <>
      <div className={`${sidebarClasses}`}>
        <div className="sidebar">
          <div className="sidebar-section">
            {sidebarRouter?.map((item, index) =>
              item?.topRouter?.map((subItem, subIndex) => (
                <NavLink
                  key={subIndex}
                  to={subItem.route === "Dashboard" ? "/" : `/${subItem.route}`}
                  className={`sidebar-page ${
                    (pathSegments.includes(subItem.route) || (pathSegments.length === 0 && subItem.route === "Dashboard"))
                      ? "active-link"
                      : ""
                  }`}
                  onClick={() => setShow(false)}
                >
                  <img
                    src={require(`../../src/assets/SidebarIcon/${subItem.icon}`)}
                    alt={subItem.route}
                  />
                  <p>{subItem.route}</p>
                </NavLink>
              ))
            )}
          </div>
          {sidebarRouter?.map((item, index) =>
            item?.bottomRouter?.map((subItem, subIndex) => (
              <NavLink
                key={subIndex}
                to={`/${subItem.route}`}
                className={`sidebar-page ${
                  pathSegments.includes(subItem.route) ? "active-link" : ""
                }`}
                onClick={() => setShow(false)}
              >
                <img
                  src={require(`../../src/assets/SidebarIcon/${subItem.icon}`)}
                  alt={subItem.route}
                />
                <p>{subItem.route}</p>
              </NavLink>
            ))
          )}
        </div>
      </div>
    </>
  );
};

export default SideBar;

