import React, { useContext, useEffect, useState } from "react";
import { UseFetch } from "../../utills/UseFetch";
import ProgressBar from "../../common/ProgressBar";
import "../../styles/Answer.scss";
import { stateContext } from "../../utills/statecontact";
import "../../styles/Answer.scss";
import AnswerCard from "./AnswerCard";
import {  useLocation, useNavigate, useParams } from "react-router-dom";
import PrimaryBtn from "../../common/PrimaryBtn";
import Loading from "../../common/Loading";
import SeconderyBtn from "../../common/SeconderyBtn";
import Toast from "../../common/Toast";

interface SubCateMandatoryDataInfo {
  solution_id: number;
  solution_name: string;
  cat_id: number;
  cat_name: string;
  sub_cat_id: number;
  sub_cat_order_id: number;
  sub_cat_name: string;
  mandatory_count: number;
  question_count: number;
  answers_count: number;
}

type SubCategoryInfo = {
  subCategoryId: number;
  sub_cat_name: string;
  qalist: any[];
};
const Answer = () => {
  const questionsPerPage = 1;
  const { solutionId, catId, subCatId, subcatOrderId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const subCatIdNumber = subCatId ? parseInt(subCatId, 10) : undefined;
  const subcatOrderIdNumber = subcatOrderId
    ? parseInt(subcatOrderId, 10)
    : undefined;
  const [currentPage, setCurrentPage] = useState<number | undefined>(
    subCatIdNumber
  );
  const [totalPages, setTotalPages] = useState<number | undefined>(
    subcatOrderIdNumber
  );

  const [currentQuestions, setCurrentQuestions] = useState<SubCategoryInfo[]>(
    []
  );
  const [selectAnswer, setSelectAnswer] = useState<Record<string, any>>({});
  const [fileName, setFileName] = useState<null>(null);

  const [file1, setFile1] = useState(true);

  const [checkselectAnswer, setCheckselectAnswer] = useState<
    Record<string, any>
  >({});

  const [selectedFileAnswer, setSelectedFileAnswer] =useState<
  Record<string, any>
>({});

  const { state:{token}, dispatch } = useContext(stateContext);


  

  // useEffect(() => {
  //   return () => {
  //     alert('You are leaving this page!');
  //   };
  // }, []);

  // useEffect(() => {
  //   const handleVisibilityChange = () => {
  //     if (document.visibilityState === 'hidden' && JSON.stringify(selectAnswer) !== JSON.stringify(checkselectAnswer)) {
  //       // Log objects to console
  //       console.log("selectAnswer:", selectAnswer);
  //       console.log("checkSelectAnswer:", checkselectAnswer);
  //     }
  //   };

  //   const handleBeforeUnload = (event:any) => {
  //     console.log(event, "event");
      
  //     console.log("selectAnswer:", selectAnswer);
      
  //     console.log("Navigating away from the page with unsaved changes.");
  //     // Execute your logic when navigating away from the page
  //     if (JSON.stringify(selectAnswer) !== JSON.stringify(checkselectAnswer)) {
  //       // Perform actions or alert the user
  //       console.log("Navigating away from the page with unsaved changes.");
  //       // You can prompt the user or perform other actions here.
  //       event.preventDefault(); // This line prevents the default browser confirmation dialog
  //     }
  //   };

  //   window.addEventListener("visibilitychange", handleVisibilityChange);
  //   window.addEventListener("beforeunload", handleBeforeUnload);
  //   console.log("adding event listeners");

  //   return () => {
  //     window.removeEventListener("visibilitychange", handleVisibilityChange);
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, [selectAnswer, checkselectAnswer]);

  // useEffect(() => {
  //   const handleBeforeUnload = (event:any) => {
  //     // Execute your logic when navigating away from the page
  //     if (JSON.stringify(selectAnswer) !== JSON.stringify(checkselectAnswer)) {
  //       // Prompt the user or perform other actions here
  //       event.preventDefault();
  //       event.returnValue = ''; // For Chrome
  //     }
  //   };

  //   window.addEventListener("beforeunload", handleBeforeUnload);
  //   console.log("Adding beforeunload event listener");

  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //     console.log("Removing beforeunload event listener");
  //   };
  // }, [selectAnswer, checkselectAnswer]);

  // useEffect(() => {
  //   const unlisten = () => {
  //     const pathname = location.pathname;
  //     const parts = pathname.split('/Answer');
  //     const trimmedPathname = parts[0] + '/Answer';

  //     if (trimmedPathname === '/Solutions/Category/SubCategory/Answer' && JSON.stringify(selectAnswer) !== JSON.stringify(checkselectAnswer)) {
  //       const confirmResult = window.confirm('You have unsaved changes. Are you sure you want to leave this page?');
  //       if (!confirmResult) {
  //         // Prevent navigation if the user cancels the confirm dialog
  //         return false;
  //       }
  //     }
  //   };

  //   window.addEventListener("beforeunload", unlisten);

  //   return () => {
  //     window.removeEventListener("beforeunload", unlisten);
  //   };
  // }, [location.pathname, selectAnswer, checkselectAnswer]);
  // const handleBeforeUnload = (event: BeforeUnloadEvent) => {
  //   event.preventDefault();
  //   event.returnValue = '';
  // };

  // useEffect(() => {
  //   window.addEventListener('beforeunload', handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, []);
  // useEffect(() => {
  //   const handleVisibilityChange = () => {
  //     console.log("visibilitychange", document.visibilityState);
      
  //     if (document.visibilityState === 'hidden' && JSON.stringify(selectAnswer) !== JSON.stringify(checkselectAnswer)) {
  //       // Log objects to console
  //       console.log("selectAnswer:", selectAnswer);
  //       console.log("checkSelectAnswer:", checkselectAnswer);
  //     }
  //   };

  //   window.addEventListener("visibilitychange", handleVisibilityChange);

  //   return () => {
  //     window.removeEventListener("visibilitychange", handleVisibilityChange);
  //   };
  // }, [selectAnswer, checkselectAnswer]);
  // const handleBeforeUnload = (event: BeforeUnloadEvent) => {
  //   const unsavedChanges = JSON.stringify(selectAnswer) !== JSON.stringify(checkselectAnswer);
  //   if (unsavedChanges) {
  //     event.preventDefault();
  //     event.returnValue = 'You have unsaved changes. Are you sure you want to leave?';
  //     return event.returnValue;
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener('beforeunload', handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, [selectAnswer, checkselectAnswer]);

  
  const {
    data,
    error: questionError,
    setRefetch: setRefetchQuestion,
  } = UseFetch(
    `answers?solution_id=${solutionId}&category_id=${catId}&subcategory_id=${currentPage}`,
    "GET"
  );

  const {
    data: designData,
    setRefetch: setRefetchCatid,
    error: subcategoryError,
  } = UseFetch(
    `answers/subcategoriesStatus?solution_id=${solutionId}&category_id=${catId}`,
    "GET"
  );


  

    const [data1, setData] = useState<SubCateMandatoryDataInfo[]>([]);

    const solutionName= data1[0]?.solution_name;

    const catName= data1[0]?.cat_name;

    


  useEffect(() => {
    setData(designData)
  },[designData])

  const { apiCall: insertApiCall } = UseFetch("/answers", "POST");

  const { apiCall: updateApiCall } = UseFetch("/answers", "PUT");

  // filtering design data based on subcategory id and assigning it to currentQuestions state variable--------->

  const filteredArray = designData?.filter(
    (item: SubCateMandatoryDataInfo) => item.sub_cat_id === currentPage
  );

  const subCategoryIds = designData?.map(
    (item: SubCateMandatoryDataInfo) => item.sub_cat_id
  );
  const indexOfLastQuestion = subCategoryIds
    ? subCategoryIds[subCategoryIds.length - 1]
    : undefined;
  const indexOfFirstQuestion =
    currentPage !== undefined ? currentPage * questionsPerPage : undefined;
  console.log(indexOfLastQuestion, indexOfFirstQuestion);

  const subOrderIds = designData?.map(
    (item: SubCateMandatoryDataInfo) => item.sub_cat_order_id
  );
  const indexOfLastSubCategory = subOrderIds
    ? subOrderIds[subOrderIds.length - 1]
    : undefined;
  const indexFirstSubCategory =
    totalPages !== undefined ? totalPages * questionsPerPage : undefined;
  console.log(indexOfLastSubCategory, indexFirstSubCategory);


  ////////////////// index based prev next sub cat name //////////////////// 
  const subCategoryAtIndex = data1[indexFirstSubCategory != null ? indexFirstSubCategory - 1 : -1];
console.log("subCategoryAtIndex", subCategoryAtIndex);

const prevSubCategoryAtIndex = data1[indexFirstSubCategory != null ? indexFirstSubCategory - 2 : -1];

const [preSubCatName, setPreSubCatName] = useState<string | undefined>(undefined);
console.log("preSubCatName", preSubCatName);

const [nextSubCatName, setNextSubCatName] = useState<string | undefined>(undefined);
console.log("nextSubCatName", nextSubCatName);

useEffect(() => {
  if (prevSubCategoryAtIndex) {
    const prevSubCatName = prevSubCategoryAtIndex.sub_cat_name;
    setPreSubCatName(prevSubCatName);
    console.log("Previous sub-category name:", prevSubCatName);
  } else {
    console.log("No previous sub-category found");
  }

  if (subCategoryAtIndex) {
    const subCatName = subCategoryAtIndex.sub_cat_name;
    console.log("Sub-category name at indexFirstSubCategory:", subCatName);
    setNextSubCatName(subCatName);
  } else {
    console.log("No sub-category found at indexFirstSubCategory");
  }
}, [prevSubCategoryAtIndex, subCategoryAtIndex]);

// Additional useEffect to handle updates when indexFirstSubCategory changes
useEffect(() => {
  const subCategoryAtIndex = data1[indexFirstSubCategory != null ? indexFirstSubCategory - 1 : -1];
  const prevSubCategoryAtIndex = data1[indexFirstSubCategory != null ? indexFirstSubCategory - 2 : -1];

  if (prevSubCategoryAtIndex) {
    const prevSubCatName = prevSubCategoryAtIndex.sub_cat_name;
    setPreSubCatName(prevSubCatName);
    console.log("Previous sub-category name:", prevSubCatName);
  } else {
    console.log("No previous sub-category found");
  }

  if (subCategoryAtIndex) {
    const subCatName = subCategoryAtIndex.sub_cat_name;
    console.log("Sub-category name at indexFirstSubCategory:", subCatName);
    setNextSubCatName(subCatName);
  } else {
    console.log("No sub-category found at indexFirstSubCategory");
  }
}, [data1, indexFirstSubCategory]);
  // const subCatpageNumber = [];
  // for (let i = 1; indexOfLastSubCategory && i <= Math.ceil(indexOfLastSubCategory / questionsPerPage); i++) {
  //   subCatpageNumber.push(i);
  // }

  // const pageNumbers = [];
  // for (let i = 1; indexOfLastQuestion && i <= Math.ceil(indexOfLastQuestion / questionsPerPage); i++) {
  //   pageNumbers.push(i);
  // }


  ///////////////////// toast /////////////////////

  const [showToast1, setShowToast1] = React.useState(false);

  const otherRoleMessage1 = {
    statusCode: 300,
    status: "Success",
    message: "Final Answer Saved Successfully",
  };


  useEffect(() => {
    if (data) {
      const currentQues: SubCategoryInfo[] = data?.flatMap(
        (item: any, index: number) => {
          return item.categorieslist?.flatMap(
            (category: any, categoryIndex: number) => {
              return category?.category?.sub_category_list;
            }
          );
        }
      ) as SubCategoryInfo[];

      setCurrentQuestions(currentQues);
    }
  }, [data]);

  const qulist = currentQuestions[0]?.qalist;

  const handleNextClick = async () => {
    try {
      qulist?.forEach(async (item: any) => {
        const questionId = item?.question_id;
        const questionType = item?.question_type;
        const userAnswer = selectAnswer[questionId];
        // console.log("questionType", questionType, questionId);
        if (
          selectAnswer[questionId] !== undefined &&
          questionId &&
          checkselectAnswer[questionId] !== undefined
        ) {
          item?.user_answers?.forEach(async (answer: any) => {
            try {
              const apiUrl = `${process.env.REACT_APP_BASE_URL}/answers/`;
              const ansId = answer.ans_id;
              let formData;
              let resUpload;
              const userAnswer = JSON.stringify(selectAnswer[questionId]);
              const inputDateString = new Date().toISOString();
              const utcDate = inputDateString.slice(0, 19).replace("T", " ");

              const postpayload = {
                question_id: questionId,
                user_answers: userAnswer,
                Remarks: "",
                created_by: 1,
                created_on: utcDate,
                is_active: true,
              };
              const putpayload = {
                question_id: questionId,
                user_answers: JSON.parse(userAnswer),
                Remarks: "",
                updated_by: 1,
                updated_on: utcDate,
                is_active: true,
              };
              if (ansId !== null) {
                if (
                  selectAnswer[questionId]?.length > 0 &&
                  JSON.stringify(selectAnswer[questionId]) !==
                  JSON.stringify(checkselectAnswer[questionId])
                ) {
                  console.log("update1 api call");
                  console.log(selectAnswer[questionId]);
                  console.log(putpayload);
                  if (questionType === "file") {
                    console.log("enter 2nd the file block");

                    let formData;
                    let resUpload;
                    // let response;
                    if (questionType === "file") {
                      console.log("enter 3rd the file block");
                      formData = new FormData();
                      if (fileName !== null) {
                        formData.append("file", fileName); // Assuming 'file' is your File object
                      } // Assuming 'file' is your File object
                      // console.log(formData);

                      resUpload = await fetch(apiUrl + "/upload", {
                        method: "POST",
                        headers: { "Authorization": `Bearer ${token}` },
                        body: formData,
                      });
                      // const resUploadNew = await fileApiCall(apiurl, formData);

                      // await insertFileApiCall(formData);
                      if (resUpload.ok) {
                        console.log("enter 4th the file block");
                        console.log(resUpload, "resUpload");
                        const fileData = await resUpload.json();
                        console.log(fileData, "fileData");
                        // console.log("File insert successfully");
                        // const fileData = resUpload.data.fileName;
                        // console.log(fileData, "fileData");

                        putpayload.user_answers = [
                          {
                            answers: fileData.fileName[0].filename,
                          },
                        ];

                        console.log(
                          putpayload.user_answers,
                          "putpayload.user_answers"
                        );

                        await updateApiCall(putpayload, questionId);
                      }
                    }
                  } else {
                    console.log("insert api call");
                    console.log(postpayload);
                    await updateApiCall(putpayload, questionId);
                  }
                } else {
                  if (
                    selectAnswer[questionId] !== undefined &&
                    (selectAnswer[questionId] === "" ||
                      (Array.isArray(selectAnswer[questionId]) &&
                        selectAnswer[questionId].length === 0))
                    &&
                    JSON.stringify(selectAnswer[questionId]) !==
                    JSON.stringify(checkselectAnswer[questionId])
                  ) {
                    console.log("update2 api call");
                    console.log(selectAnswer[questionId]);
                    console.log(putpayload);
                    if (questionType === "file") {
                      console.log("enter 2nd the file block");

                      let formData;
                      let resUpload;
                      // let response;
                      if (questionType === "file") {
                        console.log("enter 3rd the file block");
                        formData = new FormData();
                        if (fileName !== null) {
                          formData.append("file", fileName); // Assuming 'file' is your File object
                        } // Assuming 'file' is your File object
                        // console.log(formData);

                        resUpload = await fetch(apiUrl + "/upload", {
                          method: "POST",
                          headers: { "Authorization": `Bearer ${token}` },
                          body: formData,
                        });
                        // const resUploadNew = await fileApiCall(apiurl, formData);

                        // await insertFileApiCall(formData);
                        if (resUpload.ok) {
                          console.log("enter 4th the file block");
                          console.log(resUpload, "resUpload");
                          const fileData = await resUpload.json();
                          console.log(fileData, "fileData");
                          // console.log("File insert successfully");
                          // const fileData = resUpload.data.fileName;
                          // console.log(fileData, "fileData");

                          putpayload.user_answers = [
                            {
                              answers: fileData.fileName[0].filename,
                            },
                          ];

                          console.log(
                            putpayload.user_answers,
                            "putpayload.user_answers"
                          );

                          await updateApiCall(putpayload, questionId);
                        }
                      }
                    } else {
                      console.log("update api call");
                      await updateApiCall(putpayload, questionId);
                    }
                    // await updateApiCall(putpayload, questionId);
                  } else {
                    console.log(
                      "Skipping update due to null, empty string, or empty array"
                    );
                  }
                }
              } else {
                console.log(selectAnswer[questionId]);
                // Check for null or empty array before inserting
                if (
                  userAnswer !== "null" &&
                  selectAnswer[questionId]?.length > 0
                ) {
                  if (questionType === "file") {
                    console.log("enter 2nd the file block");

                    let formData;
                    let resUpload;
                    // let response;
                    if (questionType === "file") {
                      console.log("enter 3rd the file block");
                      formData = new FormData();
                      if (fileName !== null) {
                        formData.append("file", fileName); // Assuming 'file' is your File object
                      } // Assuming 'file' is your File object
                      // console.log(formData);

                      resUpload = await fetch(apiUrl + "/upload", {
                        method: "POST",
                        headers: { "Authorization": `Bearer ${token}` },
                        body: formData,
                      });
                      // const resUploadNew = await fileApiCall(apiurl, formData);

                      // await insertFileApiCall(formData);
                      if (resUpload.ok) {
                        console.log("enter 4th the file block");
                        console.log(resUpload, "resUpload");
                        const fileData = await resUpload.json();
                        console.log(fileData, "fileData");
                        // console.log("File insert successfully");
                        // const fileData = resUpload.data.fileName;
                        // console.log(fileData, "fileData");

                        postpayload.user_answers = JSON.stringify([
                          {
                            answers: fileData.fileName[0].filename,
                          },
                        ]);

                        // console.log(
                        //   postpayload.user_answers,
                        //   "postpayload.user_answers"
                        // );

                        await insertApiCall(postpayload);
                      }
                    }
                  } else {
                    console.log("insert api call");
                    console.log(postpayload);
                    await insertApiCall(postpayload);
                  }
                } else {
                  console.log("Skipping insert due to null or empty array");
                }
              }
            } catch (error) {
              console.error("Error in inner loop:", error);
            }
          });
        }
      });
    } catch (error) {
      console.error("Error in outer loop:", error);
    }
    if (
      indexFirstSubCategory !== undefined &&
      indexOfLastSubCategory !== undefined &&
      indexOfFirstQuestion !== undefined &&
      indexOfLastQuestion !== undefined &&
      indexFirstSubCategory < indexOfLastSubCategory &&
      indexOfFirstQuestion < indexOfLastQuestion
    ) {
      setTotalPages((prevTotal) =>
        prevTotal !== undefined ? prevTotal + 1 : undefined
      );
      setCurrentPage((prevPage) =>
        prevPage !== undefined ? prevPage + 1 : undefined
      );
      setRefetchQuestion(true);
      setRefetchCatid(true);
    } else if (
      indexFirstSubCategory !== undefined &&
      indexOfLastSubCategory !== undefined &&
      indexOfFirstQuestion !== undefined &&
      indexOfLastQuestion !== undefined &&
      indexFirstSubCategory >= indexOfLastSubCategory &&
      indexOfFirstQuestion >= indexOfLastQuestion
    ) {
      setShowToast1(true);
    }
  };

  const handlePreviousClick = async () => {
    try {
      qulist?.forEach(async (item) => {
        const questionId = item?.question_id;
        const questionType = item?.question_type;

        if (
          selectAnswer[questionId] !== undefined &&
          questionId &&
          checkselectAnswer[questionId] !== undefined
        ) {
          item?.user_answers?.forEach(async (answer: any) => {
            try {
              const ansId = answer.ans_id;
              const apiUrl = `${process.env.REACT_APP_BASE_URL}/answers`;
              const userAnswer = JSON.stringify(selectAnswer[questionId]);
              const inputDateString = new Date().toISOString();
              const utcDate = inputDateString.slice(0, 19).replace("T", " ");

              const postpayload = {
                question_id: questionId,
                user_answers: userAnswer,
                Remarks: "",
                created_by: 0,
                created_on: utcDate,
                is_active: true,
              };
              const putpayload = {
                question_id: questionId,
                user_answers: JSON.parse(userAnswer),
                Remarks: "",
                updated_by: 0,
                updated_on: utcDate,
                is_active: true,
              };

              if (ansId !== null) {
                if (
                  selectAnswer[questionId]?.length > 0 &&
                  JSON.stringify(selectAnswer[questionId]) !==
                  JSON.stringify(checkselectAnswer[questionId])
                ) {
                  console.log("update1 api call");
                  console.log(selectAnswer[questionId]);
                  console.log(putpayload);
                  await updateApiCall(putpayload, questionId);
                } else {
                  if (
                    selectAnswer[questionId] !== undefined &&
                    (selectAnswer[questionId] === "" ||
                      (Array.isArray(selectAnswer[questionId]) &&
                        selectAnswer[questionId].length === 0)) &&
                    JSON.stringify(selectAnswer[questionId]) !==
                    JSON.stringify(checkselectAnswer[questionId])
                  ) {
                    console.log("update2 api call");
                    console.log(selectAnswer[questionId]);
                    console.log(putpayload);
                    if (questionType === "file") {
                      console.log("enter 2nd the file block");

                      let formData;
                      let resUpload;
                      // let response;

                      formData = new FormData();
                      if (fileName !== null) {
                        formData.append("file", fileName); // Assuming 'file' is your File object
                      } // Assuming 'file' is your File object
                      // console.log(formData);

                      resUpload = await fetch(apiUrl + "/upload", {
                        method: "POST",
                        headers: { "Authorization": `Bearer ${token}` },
                        body: formData,
                      });
                      // const resUploadNew = await fileApiCall(apiurl, formData);
                      if (resUpload.ok) {
                        console.log(resUpload, "resUpload");
                        const fileData = await resUpload.json();
                        // console.log("File insert successfully");
                        // const fileData = resUpload.data.fileName;
                        // console.log(fileData, "fileData");

                        putpayload.user_answers = [
                          {
                            answers: fileData.fileName[0].filename,
                          },
                        ];

                        // console.log(
                        //   putpayload.user_answers,
                        //   "postpayload.user_answers"
                        // );

                        await updateApiCall(putpayload, questionId);
                      } else {
                        await updateApiCall(putpayload, questionId);
                        console.log("enter the else insert block");
                        console.error("File insert failed");
                        // Handle the failure as needed
                      }
                    }
                  } else {
                    console.log(
                      "Skipping update due to null, empty string, or empty array"
                    );
                  }
                }
              } else {
                console.log(selectAnswer[questionId]);
                // Check for null or empty array before inserting
                if (
                  userAnswer !== "null" &&
                  selectAnswer[questionId]?.length > 0
                ) {
                  if (questionType === "file") {
                    console.log("enter 2nd the file block");

                    let formData;
                    let resUpload;
                    // let response;
                    if (questionType === "file") {
                      console.log("enter 3rd the file block");
                      formData = new FormData();
                      if (fileName !== null) {
                        formData.append("file", fileName); // Assuming 'file' is your File object
                      } // Assuming 'file' is your File object
                      // console.log(formData);

                      resUpload = await fetch(apiUrl + "/upload", {
                        method: "POST",
                        headers: { "Authorization": `Bearer ${token}` },
                        body: formData,
                      });
                      // const resUploadNew = await fileApiCall(apiurl, formData);

                      // await insertFileApiCall(formData);
                      if (resUpload.ok) {
                        console.log("enter 4th the file block");
                        console.log(resUpload, "resUpload");
                        const fileData = await resUpload.json();
                        console.log(fileData, "fileData");
                        // console.log("File insert successfully");
                        // const fileData = resUpload.data.fileName;
                        // console.log(fileData, "fileData");

                        postpayload.user_answers = JSON.stringify([
                          {
                            answers: fileData.fileName[0].filename,
                          },
                        ]);

                        // console.log(
                        //   postpayload.user_answers,
                        //   "postpayload.user_answers"
                        // );

                        await insertApiCall(postpayload);
                      }
                    }
                  } else {
                    console.log("insert api call");
                    console.log(postpayload);
                    await insertApiCall(postpayload);
                  }
                } else {
                  console.log("Skipping insert due to null or empty array");
                }
              }
            } catch (error) {
              console.error("Error in inner loop:", error);
            }
          });
        }
      });
    } catch (error) {
      console.error("Error in outer loop:", error);
    }
    if (
      indexOfFirstQuestion !== undefined &&
      indexOfLastQuestion !== undefined &&
      indexFirstSubCategory !== undefined &&
      indexOfLastSubCategory !== undefined &&
      indexOfFirstQuestion <= indexOfLastQuestion &&
      indexFirstSubCategory <= indexOfLastSubCategory
    ) {
      setCurrentPage((prevPage) =>
        prevPage && prevPage > 1 ? prevPage - 1 : prevPage
      );
      setTotalPages((prevTotal) =>
        prevTotal && prevTotal > 1 ? prevTotal - 1 : prevTotal
      );
      setRefetchQuestion(true);
      setRefetchCatid(true);
      setFile1(false);
      setFileName(null);
    }
  };
  if (questionError === "Network Error") {
    dispatch({
      type: "NETWORK_ERROR",
      payload: true,
    });
  }

  return (
    <>
      <section id="answercategory">
        <section id="category">
          <div className="row">
            <div className="col-12">
              {filteredArray && filteredArray?.length > 0 ? (
                // rendering design data based on subcategory id----------->
                <>
                  <p className="mb-5 fs-5 fw-bold text-center border-bottom pb-3">Solution : {solutionName} <span className="ms-3">Category : {catName}</span></p>
                  {filteredArray?.map(
                    (item: SubCateMandatoryDataInfo, outerIndex: number) => (
                      <div className="cards" key={outerIndex}>
                        <h3 className="card-title">{item?.sub_cat_name}</h3>
                        <div className="d-flex align-items-center">
                          <ProgressBar
                            progress={item?.answers_count}
                            maxValue={item?.question_count}
                          />
                          <span>
                            {item?.answers_count} / {item?.question_count}
                          </span>
                        </div>
                        <div className="d-flex category-card-action">
                          <div
                            className="text-center category-card-action-icon"
                            onClick={() => handlePreviousClick()}
                          >
                            <img
                              style={{ cursor: "pointer" }}
                              className="responsive-image"
                              src={require(`../../assets/Icons/Previous.png`)}
                              alt="edit"
                              width={45}
                              height={45}
                            />
                            <p>Previous</p>
                          </div>
                          <div
                            className="text-center"
                            onClick={() => handleNextClick()}
                          >
                            <img
                              style={{ cursor: "pointer" }}
                              className="responsive-image"
                              src={require(`../../assets/Icons/next.png`)}
                              alt="edit"
                              width={45}
                              height={45}
                            />
                            <p>Next</p>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </>
              ) : (
                <Loading />
              )}
            </div>
          </div>
        </section>
      </section>
      {qulist && qulist?.length > 0 ? (
        <section id="Answer">
          <div className="row">
            <AnswerCard
              qulist={qulist}
              selectAnswer={selectAnswer}
              selectedFileAnswer={selectedFileAnswer}
              setSelectAnswer={setSelectAnswer}
              setCheckselectAnswer={setCheckselectAnswer}
              setSelectedFileAnswer={setSelectedFileAnswer}
              setFileName={setFileName}
              currentQuestions={currentQuestions}
              token={token}
              file1={file1}
            />
            <div className="col-12 col-sm-12 col-md-12 col-lg-7 col-xl-7 col-xxl-7 ">
              <div className="d-flex  mb-5 justify-content-center previous-next-btn text-center">
                <div>
                  <PrimaryBtn
                    onClick={() => handlePreviousClick()}
                    style={{
                      backgroundColor:
                        "var(--Colours-Neutral-colours-White-10)",
                      color: "var(--Colours-Primary-colour-Blue-500)",
                      border:
                        "1px solid var(--Colours-Primary-colour-Blue-500)",
                    }}
                    disabled={totalPages === 1}
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title={preSubCatName ? `${preSubCatName}` : 'No Previous Subcategory'}
                  >
                    Previous Sub category
                  </PrimaryBtn>
                </div>
                {totalPages === indexOfLastSubCategory ? (
                  <div>
                  <PrimaryBtn
                      onClick={() => handleNextClick()}
                      style={{ marginLeft: "1rem" }}
                      // disabled={totalPages === indexOfLastSubCategory}
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title={nextSubCatName ? `${nextSubCatName}` : 'No Next Subcategory'}
                    >
                      save
                    </PrimaryBtn>
                </div>
                ):(
                  <div>
                  <PrimaryBtn
                      onClick={() => handleNextClick()}
                      style={{ marginLeft: "1rem" }}
                      // disabled={totalPages === indexOfLastSubCategory}
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title={nextSubCatName ? `${nextSubCatName}` : 'No Next Subcategory'}
                    >
                      Next Sub category
                    </PrimaryBtn>
                </div>
                )}
                
                {totalPages === indexOfLastSubCategory ? (
                  <SeconderyBtn
                    onClick={() => navigate(`/Summary/${solutionId}`)}
                    style={{ marginLeft: "1rem" }}
                  // disabled={totalPages === indexOfLastSubCategory}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <path d="M16.6667 9.16667H6.52504L11.1834 4.50834L10 3.33334L3.33337 10L10 16.6667L11.175 15.4917L6.52504 10.8333H16.6667V9.16667Z" fill="#2A3E71" />
                    </svg>
                    Summary Page
                  </SeconderyBtn>
                ) : ('')}
              </div>
            </div>
          </div>
          {showToast1 && (<Toast messages={otherRoleMessage1} onClose={() => setShowToast1(false)} />)}
        </section>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default Answer;
