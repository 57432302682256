import React, { useReducer, useState, useEffect } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  Navigate,
  useNavigate,
} from "react-router-dom";
import axios from "axios";
import Navbar from "./common/Navbar";
import "./App.scss";
import Category from "./screens/Category/Category";
import Subcategory from "./screens/SubCategory/Subcategory";
import Answer from "./screens/Answer/Answer";
import Login from "./screens/Login/Login";
import { initialState, stateReducer } from "./utills/stateReducer";
import { stateContext } from "./utills/statecontact";
import Summary from "./screens/Summary/Summary";
import Breadcrumb from "./common/Breadcrumb";
import Dashboard from "./screens/Dashboard/Dashboard";
import NetworkError from "./common/NetworkError";
import PageNotFound from "./common/PageNotFound";
import Setting from "./screens/Settings/Settings";
import Popup from "./common/Popup";
import { UseFetch } from "./utills/UseFetch";
import cookies from "js-cookie";
import Versions from "./screens/Version/Version";
import CloudArchitecture from "./screens/CloudArchitecture/CloudArchitecture";
import VersionDetails from "./screens/Version/VersionDetails";
import SideBar from "./common/SideBar";
import Solutions from "./screens/solutions/Solutions";
import Loading from "./common/Loading";
import Help from "./screens/Help/Help";


function App() {
  axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
  const [state, dispatch] = useReducer<React.Reducer<typeof initialState, any>>(
    stateReducer,
    initialState
  );
  
  const [showNetworkError, setShowNetworkError] = useState(false);
  /* The `RouterLayout` function is a component that is responsible for handling the layout of the
application when the user is logged in. It sets up a timer to automatically log out the user after a
certain period of inactivity. */
  const RouterLayout = () => {
    // const confirmation = window.confirm("Are you sure you want to logout?");

    const navigate = useNavigate();
    const autoLogoutString = process.env.REACT_APP_AUTO_LOGOUT;
    const autoLogout: any = autoLogoutString
      ? parseInt(autoLogoutString)
      : undefined;
    const autoLogoutMilliseconds = autoLogout * 60000;
    const watingTime = process.env.REACT_APP_WAIT_TIME;
    const watingTimeError: any = watingTime ? parseInt(watingTime) : undefined;

    const resetLogoutTimer = () => {
      const timeoutId: NodeJS.Timeout = setTimeout(() => {
        const inputDateString = new Date().toISOString();
        const utcDate = inputDateString.slice(0, 19).replace("T", " ");
        const logOutPayload = {
          logout_time: utcDate,
        };

        const loginResponse = axios.put(
          `/users/logout/${state?.user_Data?.user_id}`,
          {
            logout_time: utcDate,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${state?.token}`,
              role_id: state?.user_Data?.role,
            },
            withCredentials: true,
          }
        );
        const removecookies = axios.patch(`/logout`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${state?.token}`,
            role_id: state?.user_Data?.role,
          },
          withCredentials: true,
        });
        cookies.remove("jwt");
        sessionStorage.clear();
        dispatch({
          type: "LOGOUT",
        });
        navigate("/");
      }, autoLogoutMilliseconds); // 10 minutes

      return timeoutId;
    };

    useEffect(() => {
      let timeoutId: NodeJS.Timeout;

      const handleUserActivity = () => {
        // Reset the logout timer on user activity
        clearTimeout(timeoutId);
        timeoutId = resetLogoutTimer();
      };

      // Add event listeners for user activity
      document.addEventListener("mousemove", handleUserActivity);
      document.addEventListener("keydown", handleUserActivity);

      // Initialize the logout timer
      timeoutId = resetLogoutTimer();

      // Cleanup: remove event listeners on component unmount
      return () => {
        document.removeEventListener("mousemove", handleUserActivity);
        document.removeEventListener("keydown", handleUserActivity);
        clearTimeout(timeoutId);
      };
    }, []);

    const [showSidebar, setShowSidebar] = useState(false);

    const toggleSidebar = () => {
      setShowSidebar(!showSidebar);
    };
    const handleLogout = async () => {
      try {
        const inputDateString = new Date().toISOString();
        const utcDate = inputDateString.slice(0, 19).replace("T", " ");
        const logOutPayload = {
          logout_time: utcDate,
        };

        await axios.put(
          `/users/logout/${state?.user_Data?.user_id}`,
          logOutPayload,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${state?.token}`,
              role_id: state?.user_Data?.role,
            },
            withCredentials: true,
          }
        );

        await axios.patch(
          `/logout`,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${state?.token}`,
              role_id: state?.user_Data?.role,
            },
            withCredentials: true,
          }
        );

        cookies.remove("jwt");
        sessionStorage.clear();

        dispatch({
          type: "LOGOUT",
        });
        navigate("/");
      } catch (error) {
        console.error("Error during logout:", error);
      }
    }; 
    useEffect(() => {
      const networkErrorTimeout = setTimeout(() => {
        setShowNetworkError(true);
      }, watingTimeError);

      return () => clearTimeout(networkErrorTimeout);
    }, []);
   
    useEffect(() => {
      const handleStorageChange = (event: StorageEvent) => {
        if (event.key === "logout" && event.newValue !== null) {
          // Logout flag changed in localStorage, perform logout
          handleLogout();
        }
      };

      window.addEventListener("storage", handleStorageChange);

      // Broadcast logout event to other windows
      const broadcastChannel = new BroadcastChannel("logout_channel");
      broadcastChannel.postMessage({ type: "logout" });

      return () => {
        window.removeEventListener("storage", handleStorageChange);
        broadcastChannel.close();
      };
    }, []);

    useEffect(() => {
      const handleLogoutMessage = (event: MessageEvent) => {
        if (event.data.type === "logout") {
          // Logout event received from another window, perform logout
          handleLogout();
        }
      };

      const broadcastChannel = new BroadcastChannel("logout_channel");
      broadcastChannel.addEventListener("message", handleLogoutMessage);

      return () => {
        broadcastChannel.removeEventListener("message", handleLogoutMessage);
        broadcastChannel.close();
      };
    }, []);

useEffect(() => {
  const handleBeforeUnload = async () => {
    try {
      const inputDateString = new Date().toISOString();
      const utcDate = inputDateString.slice(0, 19).replace("T", " ");
      const logOutPayload = {
        logout_time: utcDate,
      };

      await axios.put(
        `/users/logout/${state?.user_Data?.user_id}`,
        logOutPayload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${state?.token}`,
            role_id: state?.user_Data?.role,
          },
          withCredentials: true,
        }
      );

      await axios.patch(
        `/logout`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${state?.token}`,
            role_id: state?.user_Data?.role,
          },
          withCredentials: true,
        }
      );

      cookies.remove("jwt");
      sessionStorage.clear();
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  const handleUnload = (event: BeforeUnloadEvent) => {
    event.preventDefault();
    event.returnValue = ""; // Ensure an empty string to prevent a default dialog
  };

  const beforeUnloadListener = (event: BeforeUnloadEvent) => {
    handleBeforeUnload();
  };

  window.addEventListener("beforeunload", beforeUnloadListener);
  window.addEventListener("unload", handleUnload);

  return () => {
    window.removeEventListener("beforeunload", beforeUnloadListener);
    window.removeEventListener("unload", handleUnload);
  };
}, []);

    
    
    return (
      <div className="app">
        {state?.networkError ? (
          <>
            {showNetworkError ? (
              <NetworkError />
            ) : (
              <div>
                <Loading />
              </div>
            )}
          </>
        ) : (
          <>
            <Navbar toggleSidebar={toggleSidebar} />
            <div className="container-fluid">
              <div className="row justify-content-around">
                <div className="col-lg-1 col-md-2">
                  <SideBar show={showSidebar} setShow={setShowSidebar} />
                </div>
                <div className="col-lg-11 col-md-10 offset-0 px-4 mt-5">
                  <Breadcrumb />
                  <div className="outLet">
                    <Outlet />
                  </div>
                </div>
              </div>
            </div>
            {state?.popupData?.showPopup && <Popup />}
          </>
        )}
      </div>
    );
  };

  const router = createBrowserRouter([
    {
      path: "/",
      element: state?.isLogin ? <RouterLayout /> : <Login />,
      children: [
        {
          path: "/",
          element: state?.isLogin ? <Dashboard /> : null,
        },
        {
          path: "/Solutions",
          element: <Solutions />,
        },
        {
          path: "/Solutions/Category/:solutionId",
          element: <Category />,
        },
        {
          path: "/Solutions/Category/SubCategory/:solutionId/:catId",
          element: <Subcategory />,
        },
        {
          path: "/Solutions/Category/SubCategory/Answer/:solutionId/:catId/:subCatId/:subcatOrderId",
          element: <Answer />,
        },
        {
          path: "/Summary/:solutionId",
          element: <Summary />,
        },

        {
          path: "Settings",
          element: <Setting />,
        },

        {
          path: "/Versions",
          element: <Versions />,
        },
        {
          path: "Versions/VersionDetails/:solution_id/:version_no",
          element: <VersionDetails />,
        },
        {
          path: "Solutions/CloudArchitecture/:solution_id",
          element: <CloudArchitecture />,
        },
        {
          path: "Help",
          element:<Help/>
        }
      ],
    },
    {
      path: "*",
      element: <PageNotFound />,
    },
  ]);

  return (
    <stateContext.Provider value={{ state, dispatch }}>
      <RouterProvider router={router} />
    </stateContext.Provider>
  );
}

export default App;
