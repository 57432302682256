import React, { useEffect } from "react";
import "../../styles/SummaryCard.scss";

import axios from "axios";
interface SummaryCardCardProps {
  qulist: any;
  selectAnswer?: any;
  setSelectAnswer?: any;
  token?: any;
}

const SummaryCard: React.FC<SummaryCardCardProps> = ({
  qulist,
  selectAnswer = {},
  setSelectAnswer = () => { },
  token,

}) => {
  useEffect(() => {
    setSelectAnswer((prevSelectAnswer: any) => {
      const updatedSelectAnswer = { ...prevSelectAnswer };

      qulist?.forEach((question: any) => {
        const { question_id, question_type, answers, user_answers } = question;

        // Only update the state for questions of type 'Multi Select'
        if (question_type === "Multi Select") {
          // If there are user answers, iterate over them and update the state
          if (user_answers && user_answers.length > 0) {
            const questionAnswers: any[] = [];
            user_answers.forEach((userAnswer: any) => {
              const { ans_id, answer } = userAnswer;

              // Check if the userAnswer has 'answer' property and is an array
              if (Array.isArray(answer) && answer.length > 0) {
                // Iterate over each object in the 'answer' array
                answer.forEach((ansObj) => {
                  const updatedEntry = {
                    ans_id: ansObj?.ans_id || null,
                    answers: ansObj?.answers || null,
                  };

                  // Check if the updatedEntry is not already in the state
                  const entryExists = questionAnswers.some(
                    (entry) => entry.ans_id === updatedEntry.ans_id
                  );

                  if (!entryExists) {
                    questionAnswers.push(updatedEntry);
                  }
                });
              } else if (typeof answer === "string") {
                // If 'answer' is not an array, create a single-entry array
                const updatedEntry = {
                  ans_id: ans_id || null,
                  answers: answer || null,
                };

                // Check if the updatedEntry is not already in the state
                const entryExists = questionAnswers.some(
                  (entry) => entry.ans_id === updatedEntry.ans_id
                );

                if (!entryExists) {
                  questionAnswers.push(updatedEntry);
                }
              }
            });

            // Update the state for the current question ID
            updatedSelectAnswer[question_id] = questionAnswers;
          } else {
            // If no user answers, create a new entry with default or null values
            const possible_answer_id = answers?.[0]?.possible_answer_id;
            const updatedEntry = {
              ans_id: possible_answer_id || null,
              answers: answers || null,
            };

            // Update the state for the current question ID
            updatedSelectAnswer[question_id] = [updatedEntry];
          }
        } else if (
          question_type === "Free Text" ||
          question_type === "Date" ||
          question_type === "Dropdown" ||
          question_type === "Radio Button" ||
          question_type === "file"
        ) {
          // Update the state for the current question ID
          updatedSelectAnswer[question_id] = user_answers?.[0]?.answer;
        }
      });

      return updatedSelectAnswer;
    });
  }, [qulist]);

  const handleDownload = async (fileName1: string) => {
    console.log("fileName", fileName1);
    if (!fileName1) {
      console.error("File name is undefined");
      return;
    }

    try {
      const response = await axios.get(
        `answers/attachment?filename=${fileName1}`,
        { responseType: "blob", headers: { "Authorization": `Bearer ${token}` } }
      );
      console.log("response", response);
      if (response.status >= 200 && response.status < 300) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        // Create an invisible link element
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName1);

        // Append the link to the body and programmatically click it
        document.body.appendChild(link);
        link.click();

        // Remove the link from the DOM after the download
        document.body.removeChild(link);
      } else {
        console.error("Error downloading file. Status:", response.status);
      }
    } catch (error) {
      console.error("Error", error);
    }
  };

  return (
    <section id="summaryCard">
      <section id="Answer">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-7 col-xl-7 col-xxl-7 ">
            <div className="answer-body">
              <div className="row">
                {qulist
                  // lock question---------------->
                  // filtering questions based on question type ------------>

                  ?.filter((item: any) => item.question_type === "Date")
                  ?.map((filteredItem: any, index: number) => (
                    <div className="col-12" key={index}>
                      {filteredItem.question === "Start Date" && (
                        // Start Date component disabled ------------------------->
                        <>
                          <div className="d-flex">
                            <p className="qusetion-number me-1">
                              {filteredItem?.sno}
                            </p>
                            <span className="me-2">.</span>
                            {filteredItem?.mandatory === 1 ? (
                              <>
                                <p className="qusetion-question">
                                  {filteredItem?.question}
                                  <span>*</span>
                                </p>{" "}
                              </>
                            ) : (
                              <p className="qusetion-question">
                                {filteredItem?.question}
                              </p>
                            )}
                          </div>
                          <div className="answer-section">
                            <p className="summary-answer">{selectAnswer[
                              filteredItem.question_id
                            ] ||
                              (filteredItem.user_answers &&
                                filteredItem.user_answers?.map(
                                  (answer: any) => answer.answer
                                ))}</p>
                          </div>
                        </>
                      )}
                      {filteredItem.question === "End Date" && (
                        // End Date component disabled------------------------->
                        <>
                          <div className="d-flex">
                            <p className="qusetion-number me-1">
                              {filteredItem?.sno}
                            </p>
                            <span className="me-2">.</span>
                            {filteredItem?.mandatory === 1 ? (
                              <>
                                <p className="qusetion-question">
                                  {filteredItem?.question}
                                  <span>*</span>
                                </p>{" "}
                              </>
                            ) : (
                              <p className="qusetion-question">
                                {filteredItem?.question}
                              </p>
                            )}
                          </div>
                          <div className="answer-section">
                            <p className="summary-answer">{selectAnswer[
                              filteredItem.question_id
                            ] ||
                              (filteredItem.user_answers &&
                                filteredItem.user_answers?.map(
                                  (answer: any) => answer.answer
                                ))}</p>
                          </div>
                        </>
                      )}
                    </div>
                  ))}
              </div>

              {qulist?.map((item: any, index: number) => (
                <>
                 {item.sno !== 0 ? (
                  <>
                  <div className="d-flex">
                    {item?.question !== "Start Date" &&
                      item?.question !== "End Date" && (
                        <>
                          <p className="qusetion-number me-1">
                            {item.sno}
                          </p>
                          <span className="me-2">.</span>
                          {item?.mandatory === 1 ? (
                            <>
                              <p className="qusetion-question">
                                {item?.question}
                                <span>*</span>
                              </p>{" "}
                            </>
                          ) : (
                            <p className="qusetion-question">
                              {item?.question}
                            </p>
                          )}
                        </>
                      )}
                  </div>
                  <div>
                    {item?.question_type === "Free Text" && (
                      // Free Text component --------------------------->
                      <div className="answer-section ">
                        <p className="summary-answer">
                          {selectAnswer[item.question_id]}
                        </p>
                      </div>
                    )}
                    {item?.question_type === "Multi Select" && (
                      // Multi Select component ------------------------->
                      <div className="answer-section">
                        {item?.answerlist?.map(
                          (answerOption: any, answerIndex: number) => {
                            const selectedAnswers =
                              selectAnswer[item?.question_id] || [];
                            const matchingAnswers = selectedAnswers?.filter(
                              (selectedAnswer: any) =>
                                selectedAnswer.ans_id ===
                                answerOption.possible_answer_id
                            );

                            return (
                              <div className="summary-multiselect" key={answerIndex}>
                                {matchingAnswers.length > 0 && (
                                  <>
                                    {matchingAnswers?.map(
                                      (answer: any, index: number) => (
                                        <p className="summary-answer">{answer?.answers}</p>
                                      )
                                    )}
                                  </>
                                )}
                              </div>
                            );
                          }
                        )}
                      </div>
                    )}
                    {item?.question_type === "Dropdown" && (
                      // Dropdown component ------------------------->
                      <div className="answer-section">
                        <p className="summary-answer"> {selectAnswer &&
                          selectAnswer[item?.question_id]}</p>
                      </div>
                    )}
                    {item?.question_type === "Radio Button" && (
                      // Radio Button component ------------------------->
                      <div className="answer-section">
                        {item?.answerlist?.map((answerOption: any, answerIndex: number) => {
                          const isChecked = item?.user_answers?.filter(
                            (userAnswer: any) => userAnswer?.answer === answerOption?.answer
                          );

                          return (
                            <div className="summary-multiselect" key={answerIndex}>
                              {isChecked.length > 0 && (
                                <>
                                  {isChecked?.map((answer: any, index: number) => (
                                    <p className="summary-answer">{answer?.answer}</p>
                                  ))}
                                </>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    )}
                    {item?.question_type === "file" && (
                      // file component ------------------------->  
                      <div className="answer-section"><p className="mt-2">
                      <a
                        className="download-file"
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          handleDownload(
                            item
                              ?.user_answers[0]
                              ?.answer[0]
                              ?.answers || ""
                          );
                        }}
                      >
                        {item?.user_answers &&
                          item.user_answers[0]
                            ?.answer &&
                          item.user_answers[0]
                            .answer[0]?.answers
                          ? item.user_answers[0]
                            .answer[0].answers
                          : ""}
                      </a>
                    </p></div>
                    )}
                  </div>
                  </>
                ) : null}
                </>
              ))}
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 col-xxl-5"></div>
        </div>
      </section>
    </section>
  );
};

export default SummaryCard;
