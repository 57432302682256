import React, { useContext, useEffect, useRef, useState } from "react";
import "../../styles/VersionDetails.scss";
import { useNavigate, useParams } from "react-router-dom";
import { UseFetch } from "../../utills/UseFetch";
import axios from "axios";
import RadioBtn from "../../common/RadioBtn";
import { stateContext } from "../../utills/statecontact";
import MultiSelect from "../../common/MultiSelect";
import MyTextarea from "../../common/MyTextarea";
import Loading from "../../common/Loading";
import { useReactToPrint } from "react-to-print";
import PrimaryBtn from "../../common/PrimaryBtn";
import { solutionName } from "../../utills/CountFunc";
import Toast from "../../common/Toast";

interface Item {
  version_no: string;
  version_comments: string;
  version_description: string;
  user_name: string;
  created_on: string;
  created_by: number;
  solution_id: number;
  solution_name: string;
  order_id: number;
}

interface SolutionItem {
  solution: {
    solution_id: string;
    version_no: string;
    // Add other properties if available
  };
  // Add other properties if available
}

const VersionDetails: React.FC = () => {
  const { solution_id, version_no } = useParams();

  const {
    state: { user_Data: { user_id }, token },
    dispatch,
  } = useContext(stateContext);

  const { data: versionList ,error} = UseFetch(
    `/answers/versionList?user_id=${user_id}`,
    "GET",
    dispatch
  );
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  function isVersionWithData(version: any): version is { data: Item[] } {
    return version && version.data && Array.isArray(version.data);
  }

  const versionArray: Item[] = isVersionWithData(versionList)
    ? versionList.data
    : [];

  const navigate = useNavigate();

  const [selectedSolution, setSelectedSolution] = useState(Number(solution_id));

  const [selectedVersion, setSelectedVersion] = useState(Number(version_no));

  function buildApiUrl(): string {
    let apiUrl = `/answers`;
    if (selectedSolution && selectedVersion) {
      apiUrl += `/versioningDetails?solution_id=${selectedSolution}&version_no=${selectedVersion}`;
    }
    return apiUrl;
  }
  const { data: items } = UseFetch(buildApiUrl(), "GET", dispatch);

  const filteredData = items?.filter((item: any) => {
    return item.solution.solution_id === selectedSolution;
  });
  const solutionNames = filteredData?.map((item: any) => {
    return item.solution.solution_name;
  });

  const currentQuestions: any = items

    ?.map((item: any, index: number) => {
      return item.categorieslist.map((category: any, categoryIndex: number) => {
        return category.category.sub_category_list;
      });
    })

    .flat(2);

  const uniqueSolutionNames: string[] = [];

  const handleDownload = async (fileName: string) => {
    if (!fileName) {
      return;
    }

    try {
      const response = await axios.get(
        `answers/attachment?filename=${fileName}&mode=versioning&version_no=${version_no}`,
        { responseType: "blob", headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.status >= 200 && response.status < 300) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);

        // Append the link to the body and programmatically click it
        document.body.appendChild(link);
        link.click();

        // Remove the link from the DOM after the download
        document.body.removeChild(link);
      } else {
        console.error("Error downloading file. Status:", response.status);
      }
    } catch (error) {
      console.error("Error", error);
    }
  };
  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const handleToggleDropdown1 = () => {
    setIsOpen1(!isOpen1);
  };



  ///////////////////////// pdf Generate ////////////////////////

  const componentPdf = useRef<HTMLDivElement>(null);
  const [showToast1, setShowToast1] = React.useState(false);

  const otherRoleMessage1 = {
    statusCode: 300,
    status: "Success",
    message: "Pdf generated successfully",
  };

  const solutionName1 = () => {
    return items?.map(
      (item: any) =>
        `${item.solution.solution_name}  

          ${new Date().toTimeString()}`
    );
  };



  const onAfterPrint = () => {
    setShowToast1(true);

  };
  const [isLoading, setIsLoading] = useState(false);


  const generatePdf = useReactToPrint({ content: () => componentPdf.current, documentTitle: `${solutionName1()}`, onBeforeGetContent: () => { setIsLoading(true) }, onAfterPrint: () => { setIsLoading(false); onAfterPrint(); }, })
    ;

  function formatDate(created_on: string): string {
    const dateObject = new Date(created_on);
    return dateObject.toLocaleDateString("en-IN", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
  }

  if (error === "Network Error") {
    dispatch({
      type: "NETWORK_ERROR",
      payload: true,
    });
  }
  return (
    <>
      <div id="VersionDetails">
        <div className="filter">
          <div className="d-flex align-items-center justify-content-center">
            <div className={`custom-dropdown ms-2 ${isOpen ? "open" : ""} col-lg-3 col-md-3 `}>
              <div>
                <p className="dropdown-label text-center">Select Solution </p>
                <div
                  className="selected-option d-flex justify-content-between align-items-center"
                  onClick={handleToggleDropdown}
                >
                  {selectedSolution ? solutionName(selectedSolution) : "Select an option"}
                  <img
                    src={require("../../assets/Icons/dropDown.png")}
                    width={24}
                    height={24}
                    alt="dropdown-icon"
                  />
                </div>
              </div>

              {isOpen && (
                <div className="options">
                  {versionArray?.map((vitems: any) => {
                    if (!uniqueSolutionNames?.includes(vitems.solution_name)) {
                      uniqueSolutionNames?.push(vitems.solution_name);
                      return (
                        <option
                          className="option"
                          key={vitems.solution_id}
                          value={vitems.solution_id}
                          onClick={() => {
                            setSelectedSolution(vitems.solution_id);

                            setIsOpen(false);
                          }}
                        >
                          {vitems.solution_name}
                        </option>
                      );
                    }
                  })}
                </div>
              )}
            </div>

            <div className={`custom-dropdown ms-2 ${isOpen ? "open" : ""} col-lg-3 col-md-2`}>
              <div>
                <p className="ms-2 dropdown-label text-center">Select Version </p>
                <div
                  className="selected-option d-flex justify-content-between align-items-center"
                  onClick={handleToggleDropdown1}
                >
                  {selectedVersion ? selectedVersion : "Select an option"}
                  <img
                    src={require("../../assets/Icons/dropDown.png")}
                    width={24}
                    height={24}
                    alt="dropdown-icon"
                  />
                </div>
              </div>

              {isOpen1 && (
                <div className="options">
                  {versionArray
                    ?.filter(
                      (item: any) => item.solution_id == selectedSolution
                    )
                    ?.map((item: any) => (
                      <option
                        className="option"
                        key={item.version_no}
                        value={item.version_no}
                        onClick={() => {
                          setSelectedVersion(item.version_no);
                          setIsOpen1(false);
                        }}
                      >
                        {item.version_no}
                      </option>
                    ))}
                </div>
              )}

            </div>
            <div className="col-lg-3 col-md-4 text-end version-btn mt-4">
              <PrimaryBtn
                onClick={() => generatePdf()}
                title=''
              >
                Print
              </PrimaryBtn>
            </div>
          </div>
        </div>
        <div ref={componentPdf}>
          <div className="Details px-5" style={{ marginTop: "1rem", marginBottom: "1rem", width: "85%" }} >
            <h2 className="cat-heading fw-bold" style={{ marginTop: "3rem", marginBottom: "3rem" }}>Version Details</h2>
            {items?.map((item: any) => (
              console.log(item),

              <>
                <div className="d-flex"><p className="subcat-heading1 col-3">Solution Name  </p> <p className="subcat-heading1 col-1">-</p> <p className="subcat-heading1 col-4">{item?.solution?.solution_name}</p></div>
                <div className="d-flex"><p className="subcat-heading1 col-3">Submitted By  </p> <p className="subcat-heading1 col-1">-</p> <p className="subcat-heading1 col-4">{item?.solution?.user_name}</p></div>
                <div className="d-flex"><p className="subcat-heading1 col-3">Version No   </p> <p className="subcat-heading1 col-1">-</p> <p className="subcat-heading1 col-4">{item?.solution?.version_no}</p></div>
                <div className="d-flex"><p className="subcat-heading1 col-3">Description </p> <p className="subcat-heading1 col-1">-</p> <p className="subcat-heading1 col-4">{item?.solution?.version_description}</p></div>
                <div className="d-flex"><p className="subcat-heading1 col-3">Date  </p> <p className="subcat-heading1 col-1">-</p> <p className="subcat-heading1 col-4">{formatDate(item?.solution?.created_on)}</p></div>
              </>
            ))}
          </div>
          <div style={{ marginTop: "3rem", marginBottom: "3rem" }}>
            {items.length != 0 ? (
              items?.map((item: any, index: number) => {
                return (
                  <div key={index} className="version-question">
                    {item.categorieslist?.map(
                      (category: any, categoryIndex: number) => {
                        return (
                          <div key={categoryIndex} className="px-3">
                            <h3 className="text-center fw-bold cat-heading pt-3">
                              {category.category.cat_name}
                            </h3>
                            {category.category?.sub_category_list?.map(
                              (subCategory: any, subCategoryIndex: number) => {
                                return (
                                  <div key={subCategoryIndex}>
                                    <h5 className="subcat-heading fw-bold my-5">
                                      {subCategory.sub_cat_name}
                                    </h5>
                                    <div className="">
                                      {subCategory.qalist?.map(
                                        (question: any, index: number) => {
                                          {
                                            if (question.order_id !== 0) {
                                              return (
                                                <div className="row align-items-start pt-2">
                                                  <div className="col-6 ">
                                                    <p className="d-inline question">
                                                      {question.order_id}.
                                                    </p>
                                                    <p className="d-inline ms-2 question">
                                                      {question.question}
                                                    </p>
                                                  </div>
                                                  <div className="col-6">
                                                    {question.question_type ===
                                                      "Free Text" && (
                                                        <div>
                                                          <MyTextarea
                                                            className="form-control"
                                                            name={`question_${question.question_id}`}
                                                            value={
                                                              question.user_answers &&
                                                              question.user_answers?.map(
                                                                (answer: any) =>
                                                                  answer.answer
                                                              )
                                                            }
                                                            disabled={true}
                                                          />
                                                        </div>
                                                      )}

                                                    {question.question_type ===
                                                      "Multi Select" &&
                                                      question.question_id && (
                                                        <div className="">
                                                          <div className="row">
                                                            {question.answerlist &&
                                                              question.answerlist?.map(
                                                                (
                                                                  answerOption: any,
                                                                  answerIndex: number
                                                                ) => {
                                                                  const isChecked =
                                                                    question.user_answers?.some(
                                                                      (
                                                                        ans: any
                                                                      ) =>
                                                                        ans.answer?.some(
                                                                          (
                                                                            nestedAnswer: any
                                                                          ) =>
                                                                            answerOption.possible_answer_id ===
                                                                            nestedAnswer.ans_id
                                                                        )
                                                                    ) || false; // Set to false if not found
                                                                  return (
                                                                    <div
                                                                      key={
                                                                        answerIndex
                                                                      }
                                                                      className="col-6 my-3"
                                                                    >
                                                                      <label className="d-flex custom-checkbox">
                                                                        <div>
                                                                          <MultiSelect
                                                                            type="checkbox"
                                                                            name={`question_${question.question_id}`}
                                                                            value={
                                                                              answerOption.possible_answer_id
                                                                            }
                                                                            disabled={
                                                                              true
                                                                            }
                                                                            checked={
                                                                              isChecked
                                                                            }

                                                                          // className="hidden-checkbox"
                                                                          />
                                                                        </div>
                                                                        <div className="ms-2">
                                                                          {
                                                                            answerOption.answer
                                                                          }
                                                                        </div>
                                                                      </label>
                                                                    </div>
                                                                  );
                                                                }
                                                              )}
                                                          </div>
                                                        </div>
                                                      )}

                                                    <div className="">
                                                      <div className="row">
                                                        {question.question_type ===
                                                          "Radio Button" &&
                                                          question.follow_up ===
                                                          null &&
                                                          question.question_id &&
                                                          question.answerlist &&
                                                          question.answerlist?.map(
                                                            (
                                                              answerOption: any,
                                                              answerIndex: number
                                                            ) => {
                                                              const isChecked =
                                                                question.user_answers?.some(
                                                                  (
                                                                    userAnswer: any
                                                                  ) =>
                                                                    userAnswer.answer ===
                                                                    answerOption.answer
                                                                );
                                                              return (
                                                                <div
                                                                  key={
                                                                    answerIndex
                                                                  }
                                                                  className="col-6 "
                                                                >
                                                                  <label className="d-flex">
                                                                    <div>
                                                                      <RadioBtn
                                                                        // type="radio"
                                                                        name={`question_${question.question_id}`}
                                                                        value={""}
                                                                        checked={
                                                                          isChecked
                                                                        }
                                                                        disabled={
                                                                          true
                                                                        }
                                                                      />
                                                                    </div>
                                                                    <div className="ms-2">
                                                                      {
                                                                        answerOption.answer
                                                                      }
                                                                    </div>
                                                                  </label>
                                                                </div>
                                                              );
                                                            }
                                                          )}
                                                      </div>
                                                    </div>

                                                    <div className="row">
                                                      {question.question_type ===
                                                        "Radio Button" &&
                                                        question.follow_up &&
                                                        question.question_id &&
                                                        question.answerlist &&
                                                        question.answerlist?.map(
                                                          (
                                                            answerOption: any,
                                                            answerIndex: number
                                                          ) => {
                                                            const isChecked =
                                                              question.user_answers?.some(
                                                                (
                                                                  userAnswer: any
                                                                ) =>
                                                                  userAnswer.answer ===
                                                                  answerOption.answer
                                                              );
                                                            const singleUserAnswer =
                                                              question.user_answers?.flatMap(
                                                                (
                                                                  userAnswer: any
                                                                ) =>
                                                                  userAnswer.answer
                                                              );

                                                            const user_answer =
                                                              singleUserAnswer.find(
                                                                (answer: any) =>
                                                                  answer !== null
                                                              );

                                                            // Now, singleUserAnswer contains the first non-null element in the user_answer array

                                                            let foundItem: any =
                                                              null;

                                                            if (isChecked) {
                                                              foundItem =
                                                                question.follow_up.find(
                                                                  (item: any) => {
                                                                    return (
                                                                      item.criteria ===
                                                                      user_answer
                                                                    );
                                                                  }
                                                                );
                                                            }
                                                            return (
                                                              <div
                                                                key={answerIndex}
                                                                className="col-6 "
                                                              >
                                                                <label className="">
                                                                  <div>
                                                                    <RadioBtn
                                                                      //   type="radio"
                                                                      name={`question_${question.question_id}`}
                                                                      value={""}
                                                                      checked={
                                                                        isChecked
                                                                      }
                                                                      disabled={
                                                                        true
                                                                      }
                                                                    />
                                                                    <div className=""></div>
                                                                  </div>
                                                                  {
                                                                    answerOption.answer
                                                                  }
                                                                  {isChecked
                                                                    ? (foundItem =
                                                                      question.follow_up.find(
                                                                        (
                                                                          item: any
                                                                        ) =>
                                                                          item.criteria ===
                                                                          user_answer
                                                                      )) &&
                                                                    currentQuestions
                                                                      ?.map(
                                                                        (
                                                                          item: any
                                                                        ) =>
                                                                          item?.qalist?.find(
                                                                            (
                                                                              qitem: any
                                                                            ) =>
                                                                              foundItem.id ===
                                                                              qitem.question_id
                                                                          )
                                                                      )
                                                                      ?.map(
                                                                        (
                                                                          question: any,
                                                                          index: number
                                                                        ) => (
                                                                          <div
                                                                            key={
                                                                              index
                                                                            }
                                                                          >
                                                                            {question?.question_type ===
                                                                              "Multi Select" && (
                                                                                <div className="row">
                                                                                  {question.answerlist &&
                                                                                    question.answerlist?.map(
                                                                                      (
                                                                                        answerOption: any,
                                                                                        answerIndex: number
                                                                                      ) => {
                                                                                        const isChecked =
                                                                                          question.user_answers?.some(
                                                                                            (
                                                                                              ans: any
                                                                                            ) =>
                                                                                              ans.answer?.some(
                                                                                                (
                                                                                                  nestedAnswer: any
                                                                                                ) =>
                                                                                                  answerOption.possible_answer_id ===
                                                                                                  nestedAnswer.ans_id
                                                                                              )
                                                                                          ) ||
                                                                                          false;
                                                                                        return (
                                                                                          <div
                                                                                            key={
                                                                                              answerIndex
                                                                                            }
                                                                                            className="col-12 mb-3"
                                                                                          >
                                                                                            <label className="custom-checkbox">
                                                                                              <MultiSelect
                                                                                                type="checkbox"
                                                                                                name={`question_${question.question_id}`}
                                                                                                value={
                                                                                                  answerOption.possible_answer_id
                                                                                                }
                                                                                                disabled={
                                                                                                  true
                                                                                                }
                                                                                                checked={
                                                                                                  isChecked
                                                                                                }
                                                                                              />
                                                                                              {
                                                                                                answerOption.answer
                                                                                              }
                                                                                            </label>
                                                                                          </div>
                                                                                        );
                                                                                      }
                                                                                    )}
                                                                                </div>
                                                                              )}

                                                                            {question?.question_type ===
                                                                              "Free Text" && (
                                                                                <div className="">
                                                                                  <MyTextarea
                                                                                    className="form-control"
                                                                                    name={`question_${question.question_id}`}
                                                                                    value={
                                                                                      question.user_answers &&
                                                                                      question.user_answers?.map(
                                                                                        (
                                                                                          answer: any
                                                                                        ) =>
                                                                                          answer.answer
                                                                                      )
                                                                                    }
                                                                                    disabled={
                                                                                      true
                                                                                    }
                                                                                  />
                                                                                </div>
                                                                              )}

                                                                            {question?.question_type ===
                                                                              "Radio Button" &&
                                                                              question.question_id &&
                                                                              question.answerlist?.map(
                                                                                (
                                                                                  answerOption: any,
                                                                                  answerIndex: number
                                                                                ) => {
                                                                                  const isChecked =
                                                                                    question.user_answers?.some(
                                                                                      (
                                                                                        userAnswer: any
                                                                                      ) =>
                                                                                        userAnswer.answer ===
                                                                                        answerOption.answer
                                                                                    );
                                                                                  return (
                                                                                    <div
                                                                                      key={
                                                                                        answerIndex
                                                                                      }
                                                                                      className="col-6"
                                                                                    >
                                                                                      <label
                                                                                        className=""
                                                                                      //   style={{
                                                                                      //     display:
                                                                                      //       "block",
                                                                                      //   }}
                                                                                      >
                                                                                        <RadioBtn
                                                                                          // type="radio"
                                                                                          name={`question_${question.question_id}`}
                                                                                          value={
                                                                                            ""
                                                                                          }
                                                                                          checked={
                                                                                            isChecked
                                                                                          }
                                                                                          disabled={
                                                                                            true
                                                                                          }
                                                                                        />
                                                                                        {
                                                                                          answerOption.answer
                                                                                        }
                                                                                      </label>
                                                                                    </div>
                                                                                  );
                                                                                }
                                                                              )}
                                                                          </div>
                                                                        )
                                                                      )
                                                                    : ""}
                                                                </label>
                                                              </div>
                                                            );
                                                          }
                                                        )}
                                                    </div>

                                                    {question.question_type ===
                                                      "file" &&
                                                      question.question_id && (
                                                        <div className="mb-5">
                                                          <a
                                                            style={{ color: "var(--Colours-Primary-colour-Blue-500)" }}
                                                            href="#"
                                                            onClick={(e) => {
                                                              e.preventDefault();
                                                              handleDownload(
                                                                question
                                                                  ?.user_answers[0]
                                                                  ?.answer[0]
                                                                  ?.answers || ""
                                                              );
                                                            }}
                                                          >
                                                            {question?.user_answers &&
                                                              question
                                                                .user_answers[0]
                                                                ?.answer &&
                                                              question
                                                                .user_answers[0]
                                                                .answer[0]?.answers
                                                              ? question
                                                                .user_answers[0]
                                                                .answer[0]
                                                                .answers
                                                              : question
                                                                ?.user_answers[0]
                                                                ?.answer ===
                                                                null
                                                                ? ""
                                                                : ""}
                                                            <img
                                                              src={require("../../assets/Icons/arrow_outward.png")}
                                                              alt="link"
                                                              style={{
                                                                marginLeft: "0.5rem",
                                                              }}
                                                            />
                                                          </a>
                                                        </div>
                                                      )}

                                                    {question.question_type ===
                                                      "Dropdown" &&
                                                      question.question_id && (
                                                        <div className="">
                                                          <select
                                                            className="form-control"
                                                            disabled={true}
                                                            value={
                                                              (question.user_answers &&
                                                                question.user_answers?.map(
                                                                  (answer: any) =>
                                                                    answer.answer
                                                                ))[0] || ""
                                                            }
                                                          >
                                                            <option
                                                              value=""
                                                              disabled
                                                            >
                                                              Select an option
                                                            </option>
                                                            {question.answerlist?.map(
                                                              (
                                                                answerOption: any,
                                                                answerIndex: number
                                                              ) => (
                                                                <option
                                                                  key={
                                                                    answerIndex
                                                                  }
                                                                  value={
                                                                    answerOption.answer
                                                                  }
                                                                  className=""
                                                                >
                                                                  {
                                                                    answerOption.answer
                                                                  }
                                                                </option>
                                                              )
                                                            )}
                                                          </select>
                                                        </div>
                                                      )}
                                                    <div>
                                                      {question.question_type ===
                                                        "Date" &&
                                                        question.question_id && (
                                                          <div className="">
                                                            <div>
                                                              <input
                                                                className="form-control"
                                                                type="date"
                                                                name={`start_date_${question.question_id}`}
                                                                value={
                                                                  question.user_answers &&
                                                                  question.user_answers?.map(
                                                                    (
                                                                      answer: any
                                                                    ) =>
                                                                      answer.answer
                                                                  )
                                                                }
                                                                disabled={true}
                                                              />
                                                            </div>
                                                          </div>
                                                        )}
                                                    </div>
                                                    <p className="mb-4">
                                                      {question.question_type &&
                                                        question.question_id &&
                                                        question.question_type.startsWith(
                                                          "{"
                                                        ) ? (
                                                        JSON.parse(
                                                          question.question_type
                                                        ).Link === "link" ? (
                                                          <>
                                                            <a
                                                              href={
                                                                JSON.parse(
                                                                  question.question_type
                                                                ).url
                                                              }
                                                              target="_blank"
                                                            >
                                                              {
                                                                JSON.parse(
                                                                  question.question_type
                                                                ).name
                                                              }
                                                            </a>
                                                            <img
                                                              src={require("../../assets/Icons/arrow_outward.png")}
                                                              alt="link"
                                                            />
                                                          </>
                                                        ) : (
                                                          question.question_type
                                                            .name
                                                        )
                                                      ) : (
                                                        <></>
                                                      )}
                                                    </p>
                                                  </div>
                                                </div>
                                              );
                                            }
                                          }
                                        }
                                      )}
                                    </div>
                                    {subCategoryIndex % 2 == 0 && ( // Insert a page break after every two subcategories
                                      <div
                                        style={{ pageBreakAfter: "always", marginTop: "20px" }}
                                        className="pagerect"
                                        key={`page-break-${subCategoryIndex}`}
                                      >
                                        {isLoading && <div>Loading...</div>}  {/* <div className="page-number">Page {pageNumber}</div> */}
                                      </div>

                                    )}
                                  </div>
                                );
                              }
                            )}
                          </div>
                        );
                      }
                    )}

                  </div>
                );
              })

            ) : (
              // <h1style={{ color: "red", textAlign: "center" }}>
              //   No data available Choose another Version
              // </h1style=>
              <Loading />
            )}
          </div>
        </div>
        {showToast1 && (<Toast messages={otherRoleMessage1} onClose={() => setShowToast1(false)} />)}
      </div>
    </>
  );
};

export default VersionDetails;
