import React from "react";
import "../../styles/Solution.scss";
import { useNavigate } from "react-router-dom";
import Toast from "../../common/Toast";


interface solutionCardInf {
  solution_name: string;
  image: string;
  solution_id: number;
}
const SolutionCard = ({
  data,
  index,
  isBlur,
  sol_coming_soon,
  solIdArray,
}: {
  data: solutionCardInf;
  index: number;
  isBlur: boolean;
  sol_coming_soon: any
  solIdArray: any
}) => {
  const navigate = useNavigate();

  console.log(solIdArray, 'solIdArray');
  console.log(sol_coming_soon, 'sol_coming_soon');

  const [showToast1, setShowToast1] = React.useState(false);

  const [showToast2, setShowToast2] = React.useState(false);


  const toastMessage1 = () => {
    console.log('blur');
    setShowToast1(true);
  }

  const toastMessage2 = () => {
    console.log('not blur');
    setShowToast2(true);
  }


  const otherRoleMessage1 = {
    statusCode: 300,
    status: "Failure",
    message: "This solution is not purchased",
  };

  const otherRoleMessage2 = {
    statusCode: 300,
    status: "Alert",
    message: "Solution is coming soon",
  };
  return (
    <section className={`col-12 col-lg-4 col-md-6 col-xl-4 col-xxl-4`} key={index}>
      <div className={`solution-box ${isBlur && "opacity-25"}`}  data-bs-toggle="tooltip" data-bs-placement="top" title={isBlur ? otherRoleMessage1.message : (!sol_coming_soon.includes(data?.solution_id) ? 'Solution is available' : otherRoleMessage2.message)}>
        <div className="row">
          <div className="col-7  " style={{ paddingLeft: "2.5rem" }}>
            <div className="d-flex flex-column justify-content-between h-100">
              <div>
                <h4 className="solution-name " style={{ paddingTop: "2.5rem" }}>
                  {data?.solution_name}
                </h4>
              </div>
              <div
                className="d-flex align-items-center "
                style={{ paddingBottom: "2rem" }}
              >
                {/* 
                {isBlur ? (
                  <>
                    <p onClick={toastMessage}>Start</p>
                  </>
                ) : data?.solution_id != 9 ? (
                  <p onClick={() => navigate(`/Solutions/Category/${data.solution_id}`)}>Start</p>
                ) : (
                  <p onClick={() => navigate(`/Solutions/CloudArchitecture/${data.solution_id}`)}>Start</p>
                )} */}


                {isBlur ? (
                  <>
                    <p onClick={toastMessage1}>Start</p>
                  </>
                ) : !sol_coming_soon.includes(data?.solution_id) ? (
                  data?.solution_id != 9 ? (<p onClick={() => navigate(`/Solutions/Category/${data.solution_id}`)}>Start</p>) : (<p onClick={() => navigate(`/Solutions/CloudArchitecture/${data.solution_id}`)}>Start</p>)
                ) : (
                  <p onClick={toastMessage2}>Start</p>
                )}


                <img
                  src={require(`../../assets/solutionImage/arrow_forward.png`)}
                  alt="image"
                  width={24}
                  height={24}
                />
              </div>
            </div>
          </div>
          <div className="col-5">
            <img
              src={require(`../../assets/solutionImage/${data?.image}`)}
              alt="image"
            // width={"100%"}
            />
          </div>
        </div>
      </div>
      {showToast1 && (<Toast messages={otherRoleMessage1} onClose={() => setShowToast1(false)} />)}
      {showToast2 && (<Toast messages={otherRoleMessage2} onClose={() => setShowToast2(false)} />)}
    </section>
  );
};

export default SolutionCard;
